<template>
  <div>
    <div class="zong">
      <!-- 内容 -->
      <div class="con">
        <div class="bg"></div>
        <!-- 左盒子 -->
        <div class="left">
          <div class="niupi">
            <span>会员续费</span><br>
            <span>{{ expirationTime || '-------' }}到期</span>
          </div>
          <div class="toux">
            <img class="img" src="../../assets/imgs/toux.png" alt="">
          </div>
          <div class="perinfo">
            <div>智慧星：{{ $store.state.userInfo.phonenumber.replace($store.state.userInfo.phonenumber.substring(3, 7),
              '****') }}</div>
            <div>

              <div class="vipbg">
                VIP
              </div>
            </div>
          </div>
          <div class="text">
            {{ cardName }}
          </div>
          <div class="text2">
            未来的第一步，从指南针智填开始
          </div>
        </div>
        <!-- 右盒子 -->
        <div class="right">
          <div class="top">
            <span>{{ itValue }}</span>
            <!-- <div class="rightItem">
              <div class="bcrg">最新开通</div>
              <div class="yongh">
                <div>恭喜用户</div>
                <el-carousel height="30px" direction="vertical" :autoplay="true" :show-indicators="false">
                  <el-carousel-item v-for="item in 3" :key="item">
                    <h3 class="medium">{{ item }}</h3>
                    <span style="background-color: #eaf9f7;">182****3777</span>
                  </el-carousel-item>
                </el-carousel>
                <div>解锁会员特权</div>
              </div>
            </div> -->
          </div>
          <!-- 通过路由 切换界面 -->
          <div v-if="$route.meta.isShowNo == 1 ? true : false">
            <div class="price">
              <!-- <del>原价: ￥1888.00</del> -->
              <span>现价:<span class="sto"> ￥{{ price }}</span></span>
              <!-- <label style="font-size: 13px;margin-left: 20px;">原价599元，扫推荐人二维码享受团购价XXX元</label> -->
            </div>
            <div style="padding:0 50px">
              <span style="font-size: 14px;margin-right: 10px;height: 30px;display: inline-block">高考年份:</span>
              <el-select v-model="seValue" size="mini" @change="yearChange" style="width: 100px;">
                <el-option v-for="(item, index) in optionData" :key="index" :value="item">{{ item }}</el-option>
              </el-select>
              <span style="font-size: 14px;margin:0 10px;height: 30px;display: inline-block">套餐类型:</span>
              <el-select v-model="productTitle" size="mini" @change="tcChange" style="width: 100px;">
                <el-option v-for="(item, index) in tcData" :key="index" :value="item">{{ item }}</el-option>
              </el-select>
              <!-- <span style="font-size: 14px;margin:0 10px;height: 30px;display: inline-block;">分数:</span>
              <el-input style="display: inline-block;width: 100px;" :disabled="inputDis" size="mini"
                v-model="score"></el-input> -->
              <!-- <br />
              <span style="font-size: 14px;margin-right: 10px;height: 30px;display: inline-block">主选科目:</span>
              <el-select v-model="subject" size="mini" :disabled="subjectDis" style="width: 100px;">
                <el-option value="物理" label="物理"> </el-option>
                <el-option value="历史" label="历史"> </el-option>
              </el-select> -->
              <!-- <span
                style="font-size: 14px;margin:0 10px;height: 30px;display: inline-block">性别:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp</span>
              <el-select v-model="sex" size="mini" style="width: 100px;">
                <el-option value="0" label="男"> </el-option>
                <el-option value="1" label="女"> </el-option>
              </el-select>
               -->
               <br />
               <div v-if="isCardFlag">
                  <span style="font-size: 14px;margin: 10px 10px 10px 0;height: 30px;display: inline-block">请输入激活码:</span>
              <el-input style="display: inline-block;width: 43%;" size="mini" v-model="codeNumber" @input="codeChange"
                placeholder="请输入激活码"></el-input>
               </div>

            </div>
            <div class="payTypeDiv">
              <div class="btn" @click="codeSubmit"> 卡密激活 </div>
              <div class="btn" style="margin: 0 10px;background: #10bd18;" @click="otherPayType(1)"> 微信支付 </div>
              <div class="btn" style="background: #1976ff" @click="otherPayType(2)"> 支付宝支付 </div>
            </div>
          </div>
          <!-- 微信支付 -->
          <div v-if="$route.meta.isShowNo == 2 ? true : false">
            <div class="zf">
              <el-radio v-model="radio" label="1">
                <img src="../../assets/imgs/wechat.png" alt="">
              </el-radio>
              <el-radio v-model="radio" label="2">
                <img src="../../assets/imgs/zfbPay.png" alt="">
              </el-radio>
            </div>
            <div class="btn3" style="margin-top: 20%;">
              <div class="btn33" @click="otherPayType">立即开通</div>
              <div class="btn33 btn34" @click="$router.push('/vip3')">返回卡密支付</div>
            </div>
          </div>
          <!-- 卡密 -->
          <div v-if="$route.meta.isShowNo == 3 ? true : false">
            <div class="input">
              <div class="inputItem"><span>卡号</span> <input type="text" v-model="codeNumber" @input="codeChange"
                  placeholder="输入卡号">
              </div>
              <!-- <div class="inputItem"> <span>密码</span><input v-model="input2" placeholder="输入密码" type="password"></div> -->
            </div>
            <div class="btn3">
              <div class="btn33" @click="codeSubmit">立即开通</div>
              <div class="btn33 btn34" @click="$router.push('/vip2')">微信支付</div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <el-dialog :close-on-press-escape="false" :close-on-click-modal="false" :modal-append-to-body="false"
      :append-to-body="true" title="支付提示" :visible.sync="centerDialogVisible" width="30%" center @close="dialogClose">
      <div style="text-align:center">
        <!-- 微信 -->
        <div v-show="paytitle == '微信支付'" style="height:275px">
          <div v-loading="loading" style="width: 210px; height: 210px;margin: 0 auto;">
            <div v-if="payTimeFlag" style="padding-left: 24px">
              剩余支付时间
              <span style="color: orange">{{ count }}</span>
            </div>
            <div style="text-align:center ;" v-if="payTimeFlag" ref="qrCode" id="qrCode"></div>
            <div v-if="resetPayflag" style="
                        z-index: 10;
                        width: 210px;
                        height: 210px;
                        background: rgba(0, 0, 0, 0.7);
                        color: white;
                        text-align: center;
                        cursor: pointer;
                        color: white;
                        box-sizing: border-box;
                        padding-top: 80px;
                      ">
              <p>二维码已过期</p>
              <p>请重新刷新</p>
            </div>
            <div style="margin-top: 10px;text-align: center;">
              <img src="@/assets/imgs/sao.png" style="width:20px ;vertical-align: top;" alt="">
              <span>微信扫一扫支付</span>
            </div>
          </div>
        </div>
        <!-- 支付宝 -->
        <div v-show="paytitle == '支付宝支付'" style="height:275px">
          <div v-loading="loadingZFB" style="width: 210px; height: 210px;margin:auto">
            <iframe :srcdoc="qianHtml" frameborder="no" border="0" marginwidth="0" marginheight="0" scrolling="no"
              width="210" height="210" style="overflow:hidden;">
            </iframe>
          </div>
          <div style="margin-top: 10px;text-align: center;">
            <img src="@/assets/imgs/sao.png" style="width:20px ;vertical-align: top;" alt="">
            <span>支付宝扫一扫支付</span>
          </div>
        </div>
      </div>

    </el-dialog>
    <div class="connet">
      <!-- 路由盒子 -->
      <!-- <router-view /> -->
      <becomevip1 ref="becomevip1"></becomevip1>
    </div>
  </div>
</template>

<script>
import { REQWXPAY, REQZFBPAY, tradeQuery, activateCode, checkVIP, tradeQueryZFB } from '@/api/pay.js'
import { REGUserEditNoPass } from '@/api/user'
import becomevip1 from "./becomeVip1.vue"
import QRCode from 'qrcodejs2'
name: 'becomeVip';
export default {
  data() {
    return {
      isCardFlag:false,
      itValue: '在线支付会员卡',
      seValue: '3',
      cardName: '志愿卡',
      codeNumber: '',
      input2: '',
      radio: '1',
      expirationTime: '',
      optionData: [],
      tcData: ['自主卡', '智能卡'],
      productTitle: '智能卡',
      subject: '物理',
      subjectDis: false,
      sex: '0',
      score: '',
      inputDis: false,
      timer: null, // 轮询查询订单
      paytimer: null, // 5分钟倒计时的timer
      timerZFB: null, // 支付宝轮询
      orderNo: '',
      orderNoZFB: '',
      seconds: 300, // 5分钟的秒数
      count: '05分00秒',
      payTimeFlag: false,
      resetPayflag: false,
      paytitle: '',
      centerDialogVisible: false,
      isBuy: true,
      activationCode: '',
      validCode: '',
      fakeCode: '',
      price: 599.00,
      grade: '',
      cardType: '',
      qianHtml: '',
      loading: false,
      loadingZFB: false,
      isShow: false,
    };
  },
  components:{
    becomevip1
  },
  methods: {
    async codeChange() {
      if (this.codeNumber) {
        // 如果有值，判断激活码是否是体验卡
        await checkVIP({ codeNumber: this.codeNumber }).then(res => {
          if (res.msg == 1) {   //查无此卡 || 非体验卡
            this.expirationTime = this.seValue + '-09-30'
          } else {     //体验卡
            this.expirationTime = res.msg.split(':')[1].substr(0, 10)
          }
        })
      }
    },
    // 激活码激活
    codeSubmit() {
      if(!this.isCardFlag){
        this.isCardFlag = true;
          return
      }


      this.codeChange().then(response => {
        let params = {
          expirationTime: this.expirationTime + ' 00:00:00',
          codeNumber: this.codeNumber,
          phoneNumber: localStorage.getItem('phonenumber')
        }

        this.$confirm(`该卡的会员到期时间为${this.expirationTime}，是否继续`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(()=>{
                       activateCode(params).then( async res => {
          if (res.code == 200) {
            this.$message.success('激活成功')
            await this.$store.dispatch('setUserinfo')
            //  this.$refs.becomevip1.gethost()   查询历史激活记录
            this.$router.push('/index')

          }
        })
            })

      })
    },
    // 微信或支付宝支付
    otherPayType(radio) {
      this.radio = radio;
      if (this.radio == 1) {   //微信支付
        this.wxpay()
      } else if (this.radio == 2) {  //支付宝支付
        this.zfbpay()
      }
    },
    // 微信支付
    wxpay() {
      const self = this;
      // if (!this.score) {
      //   this.$message.warning('请输入高考分数')
      //   return
      // }
      // if (isNaN(Number(this.score))) {
      //   this.$message.warning('请输入准确的高考分数')
      //   return
      // }
      // if (!(Number(this.score) >= 1 && Number(this.score) <= 750)) {
      //   this.$message.warning('请输入准确的高考分数')
      //   return
      // }
      this.$alert('务必填写真实情况，一旦提交不可修改。', '提示', {
        confirmButtonText: '确定',
        callback: async action => {
          if (action == 'confirm') {

            let thPrice = this.$store.state.userInfo.thPrice;
            // 如果thPrice为0，则说明未享受优惠。
            let thPriceText = thPrice == 0 ? '' : `原价599元，扫推荐人二维码享受团购价${thPrice}元，`
            let text = `您当前选择的套餐为${this.productTitle}，${thPriceText}会员到期时间为${this.expirationTime}，是否继续`
            this.$confirm(text, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(async () => {
              // const objCopy = JSON.parse(JSON.stringify(this.$store.state.userInfo))
              // objCopy.score = this.score;
              // objCopy.subject = this.subject;

              // const res = await REGUserEditNoPass(objCopy)

              this.centerDialogVisible = true
              this.paytitle = '微信支付'
              this.loading = true
              this.resetPayflag = false
              this.payTimeFlag = true
              const obj = {
                phoneNumber: localStorage.getItem('phonenumber'),
                productTitle: this.productTitle,
                totalAmount: this.price,
                // totalAmount: '0.01',
                payType: '微信支付',
                expirationTime: this.expirationTime + ' 00:00:00',
                productType: '1',    //自主卡 智能卡 为 1，课程为2，咨询为3.
                productId: ''        //自主卡 智能卡为 '', 课程为课程id，咨询为咨询id
              }
              await REQWXPAY(obj).then((res) => {
                self.loading = false
                self.seconds = 300
                self.Time()
                self.$nextTick(() => {
                  const el = this.$refs.qrCode
                  document.getElementById('qrCode').innerHTML = ''
                  new QRCode(el, {
                    width: 200,
                    height: 200,
                    text: res.data.codeUrl
                  })
                })
                self.orderNo = res.data.orderNo
              })
              if (!this.timer) {
                this.timer = window.setInterval(async () => {
                  const res = await tradeQuery({ orderNo: self.orderNo })
                  if (res.code == 200) {
                    this.clearAllTimer()
                    this.$message.success('支付成功')
                    // 支付成功之后，获取个人信息状态。
                    await self.$store.dispatch('setUserinfo')
                    this.centerDialogVisible = false
                    this.isShow = false
                    this.count = '05分00秒'
                    this.payTimeFlag = false
                    this.$router.push('/index')
                  }
                }, 1000)
              }
            })
          }
        }
      });
    },
    // 支付宝支付
    zfbpay() {

      // if (!this.score) {
      //   this.$message.warning('请输入高考分数')
      //   return
      // }
      // if (isNaN(Number(this.score))) {
      //   this.$message.warning('请输入准确的高考分数')
      //   return
      // }
      // if (!(Number(this.score) >= 1 && Number(this.score) <= 750)) {
      //   this.$message.warning('请输入准确的高考分数')
      //   return
      // }
      this.$alert('务必填写真实情况，一旦提交不可修改。', '提示', {
        confirmButtonText: '确定',
        callback: action => {
          if (action == 'confirm') {
            let thPrice = this.$store.state.userInfo.thPrice;
            // 如果thPrice为0，则说明未享受优惠。
            let thPriceText = thPrice == 0 ? '' : `原价599元，扫推荐人二维码享受团购价${thPrice}元，`
            let text = `您当前选择的套餐为${this.productTitle}，${thPriceText}会员到期时间为${this.expirationTime}，是否继续`
            this.$confirm(text, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(async () => {
              // const objCopy = JSON.parse(JSON.stringify(this.$store.state.userInfo))
              // objCopy.score = this.score;
              // objCopy.subject = this.subject;

              // const res = await REGUserEditNoPass(objCopy)
              const self = this
              this.paytitle = '支付宝支付'
              this.loadingZFB = true
              this.centerDialogVisible = true
              const obj = {
                phoneNumber: localStorage.getItem('phonenumber'),
                productTitle: this.productTitle,
                totalAmount: this.price,
                // totalAmount: '0.01',
                payType: '支付宝支付',
                expirationTime: this.expirationTime + ' 00:00:00',
                productType: '1',    //自主卡 智能卡 为 1，课程为2，咨询为3.
                productId: ''        //自主卡 智能卡为 '', 课程为课程id，咨询为咨询id
              }
              await REQZFBPAY(obj).then((res) => {
                self.loadingZFB = false
                self.qianHtml = res.data.iframe
                self.orderNoZFB = res.data.outTradeNo
                return
                var form = res.data
                const div = document.createElement('div') // 创建div
                div.innerHTML = form // 将返回的form 放入div
                document.body.appendChild(div)
                document.forms[1].setAttribute('target', '_blank') // 新开窗口跳转
                document.forms[1].submit()
              })
              if (!this.timerZFB) {
                this.timerZFB = window.setInterval(async () => {
                  const res = await tradeQueryZFB({ orderNo: this.orderNoZFB })
                  if (res.data.status == 'TRADE_SUCCESS') {
                    this.clearAllTimer()
                    this.$message.success('支付成功')
                    // 支付成功之后，获取个人信息状态。
                    await self.$store.dispatch('setUserinfo')
                    this.centerDialogVisible = false
                    this.isShow = false
                    this.$router.push('/index')
                  }
                }, 1000)
              }
            })
          }
        }
      });






    },
    dialogClose() {
      this.qianHtml = ''
      this.clearAllTimer()
    },
    // 定时器每过1秒参数减1
    Time() {
      this.paytimer = window.setInterval(() => {
        this.seconds -= 1
        this.countDown()
      }, 1000)
    },
    // 清除所有计时器的公用方法
    clearAllTimer() {
      window.clearInterval(this.timer)
      this.timer = null
      window.clearInterval(this.paytimer)
      this.paytimer = null
      window.clearInterval(this.timerZFB)
      this.timerZFB = null
    },
    // 天 时 分 秒 格式化函数
    countDown() {
      let d = parseInt(this.seconds / (24 * 60 * 60))
      d = d < 10 ? '0' + d : d
      let h = parseInt((this.seconds / (60 * 60)) % 24)
      h = h < 10 ? '0' + h : h
      let m = parseInt((this.seconds / 60) % 60)
      m = m < 10 ? '0' + m : m
      let s = parseInt(this.seconds % 60)
      s = s < 10 ? '0' + s : s
      this.count = m + '分' + s + '秒'
      if (this.count == '00分00秒') {
        this.clearAllTimer()
        document.getElementById('qrCode').innerHTML = ''
        this.resetPayflag = true
        this.payTimeFlag = false
        this.loading = false
      }
    },
    yearChange(e) {
      this.expirationTime = this.seValue + '-09-30'
    },
    tcChange() {
      const userType = this.$store.state.userInfo.userType
      if(userType == '04'){
        this.price = 599.00
        this.productTitle = '智能卡'
        this.$message.warning('你已开通自主卡，请升级智能卡')
        return
      }
      if (this.productTitle == '自主卡') {
        this.price = 299.00
      } else if (this.productTitle == '智能卡') {
        if (userType == '04') {
           this.price = 599.00
        } else {
          this.price = 599.00
        }

      }
    },
  },
  created() {

    this.$watch('$route', (to,) => {
      // 在路由变化时执行的逻辑
      switch (to.meta.isShowNo) {
        case 1:
          this.itValue = '在线支付正式卡';
          break;
        case 2:
          this.itValue = '微信支付';
          break;
        case 3:
          this.itValue = '卡密支付';
          break;
        default:
          break;
      }
    })
    for (let index = 0; index < 4; index++) {
      this.optionData.push(new Date().getFullYear() + index)
      this.seValue = new Date().getFullYear()
      this.expirationTime = new Date().getFullYear() + '-09-30'
    }
    const userInfo = this.$store.state.userInfo;
    if (!userInfo.score) {    //如果分数不存在，说明是新用户，可以修改
      this.inputDis = false;
    } else {      //分数存在
      this.score = userInfo.score;
      this.inputDis = true;
    }
    if (!userInfo.subject) {    //科目不存在，说明是新用户。
      this.subjectDis = false
    } else {
      this.subject = userInfo.subject;
      this.subjectDis = true;
    }
     window.scrollTo({
                top: 0,
                behavior: 'smooth' // 平滑滚动
            });
            this.tcChange()
  }
}
</script>

<style lang="scss" scoped>
.zong {
  width: 100%;
  height: 280px;
  background-color: #30c9b2;
  position: relative;

  .bg {
    width: 100%;
    height: 28%;
    position: absolute;
    background-color: #30c9b2;
    opacity: 0.1;
    z-index: 1;
  }
}

.con {
  width: 78%;
  height: 324px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 5px #9a9898;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -70%);
}

.left {
  width: 38%;
  height: 79%;
  float: left;
  margin: 3.5% 0 0 3.5%;
  background-image: url("../../assets/imgs/vipCard.png");
  background-size: 100% 100%;
  position: relative;
  z-index: 5;

  .niupi {
    width: 30%;
    height: 45px;
    // background-color: red;
    background-image: url("../../assets/imgs/niupi.png");
    background-size: 100% 100%;
    position: absolute;
    left: 70%;
    top: 8%;

    span {
      width: 100%;
      display: inline-block;
      text-align: center;
      font-size: 13px;
      font-weight: 600;
    }
  }

  .toux {
    width: 40px;
    height: 15%;
    border: 2px solid #1b1c1b;
    border-radius: 50%;
    position: absolute;
    top: 11%;
    left: 5%;

    .img {
      width: 100%;
      height: 100%;
    }
  }

  .perinfo {
    width: 40%;
    position: absolute;
    top: 12%;
    left: 18%;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #3f3f3f;

    select {
      background-color: rgba($color: #ffffff, $alpha: 0.5);
      border: 0;
      margin-top: 3%;
    }

    .vipbg {
      width: 30%;
      height: 15px;
      color: #bbac96;
      background-image: url("../../assets/imgs/viprect.png");
      background-size: 100% 100%;
      text-align: center;
      line-height: 15px;
      font-size: 10px;
      position: absolute;
      top: 6%;
      left: 84%;
      cursor: pointer;
    }
  }

  .text {
    width: 100%;
    height: 40%;
    text-align: center;
    font-size: 50px;
    font-family: PingFang SC;
    font-weight: 800;
    letter-spacing: 10px;
    // 文字渐变代码
    background: linear-gradient(90deg, #fef0cb, #fdd793);
    // -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    position: absolute;
    top: 39%;
    left: -16%;
  }

  .text2 {
    width: 100%;
    height: 10%;
    text-align: center;
    font-size: 11px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    position: absolute;
    top: 73%;
    left: -17%;
  }
}

.right {
  width: 58%;
  height: 96%;
  float: right;
  background-color: #fff;
  position: relative;
  border-radius: 50px;

  .top {
    width: 91%;
    height: 20%;
    border-radius: 20px 20px 0 0;
    margin: 44px;

    span {
      display: inline-block;
      height: 40px;
      font-family: PingFang SC;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      // float: left;
    }

    .rightItem {
      float: right;
      position: relative;
      width: 100%;
      height: 60px;

      .bcrg {
        width: 17%;
        height: 30px;
        background-color: #30c9b2;
        border-radius: 30px 64px 20px 28px;
        text-align: center;
        font-size: 17px;
        line-height: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #fff;
        position: absolute;
        left: 43%;
        top: -69%;
      }

      .yongh {
        ::v-deep(.el-carousel__indicators--vertical) {
          display: none;
        }

        ::v-deep(.el-carousel--vertical) {
          width: 33%;
        }

        ::v-deep(.is-active) {
          background-color: #eaf9f7 !important;
        }

        width: 40%;
        height: 30px;
        line-height: 30px;
        position: absolute;
        top: -67%;
        left: 60%;
        display: flex;
        justify-content: center;

        span {
          color: #f3d901;
          font-size: 13px;
        }
      }
    }
  }

  .price {
    // width: 43%;
    height: 30px;
    line-height: 30px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 7%;
    top: 36%;

    del {
      margin-top: 3px;
    }

    .sto {
      color: #30c9b2;
      font-size: 20px;
      font-weight: 600;
    }
  }

  .payTypeDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 50px;
  }

  .btn {
    // width: 62%;
    height: 40px;
    flex: 1;
    background-color: #f8bf79;
    color: #fff;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
    border-radius: 25px;
    // position: absolute;
    top: 77%;
    left: 8%;
    cursor: pointer;
  }

  .btn2 {
    width: 58%;
    background-color: #30c9b2;
    top: 65%;
  }
}

.connet {
  width: 78%;
  // height: 600px;
  // background-color: rgb(250, 250, 250);
  margin: auto;
  margin-top: 8%;
}

.input {
  width: 80%;
  display: flex;
  // justify-content: space-around;
  margin-left: 10%;

  .inputItem {
    width: 80%;
    display: flex;

    span {
      display: block;
      width: 20%;
      height: 30px;
      text-align: center;
      line-height: 30px;
    }

    input {
      width: 100%;
      height: 30px;
      font-size: 12px;
      border: 1px solid #9e9e9e;
      border-radius: 5px;
      text-align: center;
      color: #2f2f2f;
    }
  }
}

.btn3 {
  width: 76%;
  display: flex;
  justify-content: space-between;
  margin-left: 11%;
  margin-top: 10%;

  .btn33 {
    width: 48%;
    background-color: #30c9b2;
    height: 35px;
    border-radius: 15px;
    text-align: center;
    line-height: 35px;
    color: #eaf9f7;
    cursor: pointer;
  }

  .btn34 {
    background-color: #f8bf79;
  }
}

.zf {
  width: 100%;
  position: absolute;
  top: 119px;
  left: 132px;

  img {
    width: 60px;
    height: 60px;
  }
}
</style>
